<template>
<v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
  <v-main >
    <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
      
        <v-row
            align="center"
            justify="center"
            class=" mb-5"
          >

            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text  >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Reviews({{totalCount}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                      <v-btn
                        elevation="0"
                        @click="dialogCoupon = true"
                        :ripple="false"
                        small
                        style="float: left;margin-right:15px;"
                        color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                        >Add Review </v-btn
                      >
                      <!-- <v-btn
                        elevation="0"
                        @click="dialogAssign2 = true"
                        :ripple="false"
                        small
                        style="float: left;margin-right:15px;"
                        color="blue"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                        >Assign to client </v-btn
                      > -->
                     
                      <!-- <v-btn
                        elevation="0"
                        @click="dialogAssign3 = true"
                        :ripple="false"
                        small
                        style="float: left;margin-right:15px;"
                        color="orange"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                        >Update Thank you page</v-btn
                      > -->
                      </v-col>
                      <v-col cols="12"
                      md="12" >
                            <v-text-field
                               v-model="search"
                                label="Search"
                                single-line
                                
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getProducts"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            class="table"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            :expanded.sync="expanded"
                            show-expand
                            v-sortable-data-table
                            v-model="selectedItems"
                              show-select
                            @sorted="saveOrder"
                            :items-per-page="itemsPerPage"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [-1]
                                  }"
  
                        >
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">

                          <p class="mt-3"> {{ item.description }}</p>

                           <v-divider class="mx-4"></v-divider>


                              <v-card-text v-if="item.file">
                                      <img
                                              v-if="isImage(item.file)"
                                              class="w-51 shadow-lg mx-auto "
                                              :src="base_url_img+'review/'+item.file"
                                              lazy-src="https://picsum.photos/id/11/100/60"
                                              style="width: 100%; height: 400px;"

                                            >
                                            </img>
                                            <video
                                            class="w-51 shadow-lg mx-auto "
                                            v-else-if="isVideo(item.file)"
                                            :src="base_url_img+'review/'+item.file"
                                            controls
                                            style="width: 100%; height: 400px;"
                                          ></video>
                              </v-card-text>
                          </td>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.description="{ item }">
                        <div class="text-ellipsis" title="View full text">
                          {{ item.description }}
                        </div>
                      </template>
                        <template v-slot:item.created_at="{ item }">
                            {{dateFormat(item.created_at)}}
                        </template>
                         <template v-slot:item.updated_at="{ item }">
                            {{dateFormat(item.updated_at)}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                        <template v-slot:item.rating="{ item }">
                            <v-rating
                                v-model="item.rating"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$vuetify.icons.ratingFull"
                                readonly
                                size="15"
                            ></v-rating>
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                         <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="openEditReview(item)"
                            x-small
                            color="blue"
                          >
                            {{getUserName(item.users)}}
                          </v-btn>
                        </template>
                        <template v-slot:item.edit_review="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="review = item,review.old_file = item.file, review.file = [], dialogCoupon = true"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                       
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getProducts"
                                class="mb-5"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>

      
           <v-dialog
        persistent
      v-model="dialogAssign"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
          Add new Product
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Product
        </v-card-title>
        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                v-model="product.name"
                label="Name"
                outlined
                dense
          ></v-text-field>
          <v-divider class="mb-5"></v-divider>
          <div v-for="(link, i) in product.items" :key="i">
          <v-text-field
                v-model="link.price"
                label="Price"
                prefix="$"
                outlined
                dense
                v-mask="'###########################'"
          ></v-text-field>
              <v-select
              label="Type"
              outlined
                dense
            v-model="link.type"
            :items="['Subscription','Onetime']"
          > </v-select>
                          <v-select
                            label="Billing Period"
                            v-show="link.type=='Subscription'"
                            outlined
                              dense
                          v-model="link.period"
                          :items="['Monthly','Every 3 months','Every 6 months','Yearly','Custom']"
                        ><template v-slot:append-outer>
                          <v-btn icon="fa-close" @click="remove(i)" v-if="i != 0">
                           
                            <v-icon  style="color:red!important;margin-bottom: 15px;" class="text-typo">fas fa-times-circle</v-icon>
                          </v-btn>
                          </template> </v-select>

                          <v-text-field
                            v-model="link.interval_length"
                            prefix="every"
                            dense
                            outlined
                            v-if="link.type=='Subscription' && link.period =='Custom'"
                            v-mask="'##'"
                      ><template v-slot:append-outer>
                        <v-select
                        outlined
                            v-show="link.type=='Subscription'"
                              dense
                              style="margin-top:-8px"
                          v-model="link.interval"
                          :items="['day','week','month']"
                        ></v-select>
                      </template></v-text-field>
          <v-divider class="mb-5"></v-divider>
          </div>
                        <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-primary
                        py-5
                        px-6
                        mt-6
                        mb-5
                        me-2
                      "
                      color="blue"
                      @click="add()"
                    >
                      Add item
                    </v-btn>
                    <v-divider class="mb-5"></v-divider>
                    <v-checkbox
                          v-model="product.trial_flag"
                          :label="`Add free trial`"
                          style="margin-top: -15px;"
                        ></v-checkbox>
                        <v-text-field
                            v-model="product.trial"
                            label="Trial days"
                            v-show="product.trial_flag"
                            suffix="days"
                            outlined
                            dense
                            v-mask="'###########################'"
                      ></v-text-field>
                      <v-text-field
                            v-model="product.success_url"
                            label="Success URL"
                            outlined
                            dense
                      ></v-text-field>
          <v-file-input
          accept="image/*"
          label="Product Image"
          v-model="product.image"
          outlined
                dense
        ></v-file-input>
          <v-switch
          label="Show to all client"
            v-model="product.all_account"
            inset
          ></v-switch>
          <v-select
          v-if="product.all_account == 0"
            v-model="product.list_account"
            :items="clients"
            chips
            item-text="name"
            item-value="user_id"
            label="Select users"
            multiple
            solo
          >
          <template v-slot:prepend-item>
          <v-list-item>
            <v-list-item-content>
              <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        </v-select>
      
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!product.id"
            color="#4caf50"
            text
            :disabled="loadingAssign"
            @click="addPromo()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
                                v-model="dialog_basic"
                                max-width="500px"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="12"
                                          md="12"
                                        >
                                          <v-text-field
                                            v-model="editedItemBasic.item"
                                            label="Enter Item"
                                          ></v-text-field>
                                        </v-col>
                                        
                                      </v-row>
                                    </v-container>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeBasic"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="saveBasic(selected_item)"
                                    >
                                      Save
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog
                                persistent
                              v-model="dialogAssign2"
                              width="500"
                            >

                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Assign to client
                                </v-card-title>

                                <v-card-text style="margin-top:30px;"> 
                                  <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                                        <span style="color:#5D6167;">Clients</span>
                                        <!-- <v-select
                                                    item-text="name"
                                                    placeholder="Select clients"
                                                    item-value="id"
                                                    :disabled="loadingAssign"
                                                    v-model="selectedCompany"
                                                    :items="clients"
                                                    ></v-select> -->
                                                    <v-select
                                                      v-model="selectedReviewClients"
                                                      :items="clients"
                                                      chips
                                                      item-text="name"
                                                      item-value="user_id"
                                                      label="Select users"
                                                      multiple
                                                      solo
                                                    >
                                                     <template #selection="selection">
                                                    <v-chip @click="gotoClient(selection.item.user_id)" v-text="selection.item.name"></v-chip>
                                                  </template>     
                                                   <template v-slot:prepend-item>
                                                            <v-list-item>
                                                              <v-list-item-content>
                                                                <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                                                              </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider class="mt-2"></v-divider>
                                                            <v-list-item
                                                                ripple
                                                                @mousedown.prevent
                                                                @click="toggleAll"
                                                              >
                                                                <v-list-item-action>
                                                                  <v-icon class="material-icons-round" style="font-size: 18px;margin-left: 3px;">
                                                                    {{ selectedReviewClients.length == clients.length ? 'check_box': 'check_box_outline_blank' }}
                                                                  </v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                  <v-list-item-title>
                                                                    Select All
                                                                  </v-list-item-title>
                                                                </v-list-item-content>
                                                              </v-list-item>
                                                          </template>
                                                  </v-select>
                                      
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    :disabled="loadingAssign"
                                    @click="changeCompany()"
                                  >
                                    Submit
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    :disabled="loadingAssign2"
                                    @click="dialogAssign2 = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                                persistent
                              v-model="dialogAssign3"
                              width="500"
                            >

                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  Thank you page
                                </v-card-title>

                                <v-card-text style="margin-top:30px;"> 
                                  <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                                        <v-text-field
                                          v-model="success_url"
                                          label="Page URL"
                                          outlined
                                          dense
                                    ></v-text-field>
                                    <!-- <v-checkbox
                                    v-model="success_url_existing"
                                    :label="`Update existing Payment links`"
                                    style="margin-top: -15px;"
                                  ></v-checkbox> -->
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    :disabled="loadingAssign"
                                    @click="changeSuccessURL()"
                                  >
                                    Submit
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    text
                                    :disabled="loadingAssign2"
                                    @click="dialogAssign3 = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>


    <v-dialog
        persistent
      v-model="dialogAddFA"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!fa.id">
          Add new Focus Area
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Focus Area
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                label="Name"
                v-model="fa.name"
          ></v-text-field>
        
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!fa.id"
            color="#4caf50"
            text
            :disabled="loadingAssign"
            @click="addFocusArea()"
          >
            Submit
          </v-btn> <v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editFocusArea()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAddFA = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAddL"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!location.id">
          Add new Location
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Location
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 

          <vuetify-google-autocomplete
                                    id="map"
                                    v-model="location.name"
                                    label="Location"
                                    color="#e91e63"
                                     :rules="rules"
                                    class="font-size-input input-style"
                                    placeholder="Enter a location"
                                    v-on:placechanged="getAddressData"
                                    country="us"
                                ></vuetify-google-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!location.id"
            color="#4caf50"
            text
            :disabled="loadingAssign"
            @click="addLocation()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editLocation()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAddL = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogCustomLink"
      width="500"
    >

      <v-card>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
        <v-card-title class="text-h5 grey lighten-2" v-if="editFlag">
          Edit custom menu link 
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Create a custom menu link 
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Link title</span>
          <v-text-field
                v-model="link.title"
                required
                :rules="nameRules"
                solo
          ></v-text-field>
          <!-- <span style="color:#5D6167;">Link icon</span>
          <v-dialog
            v-model="showDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="700px"
            max-height="850px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="icon"
                :prepend-inner-icon="selectedIcon"
                :label="selectedIcon"
                v-bind="attrs"
                v-on="on"
                color="deep-purple"            
                readonly
                solo  
              >
              </v-text-field>
            </template>
            <v-card>
              <v-app-bar
                color="white"
                scroll-target="#scrolling-techniques-7"
                elevation="0"
              >
                <v-text-field
                  v-model="searchIcon"
                  placeholder="Search icon"
                  prepend-icon="mdi-magnify"
                  type="text"
                  onautocomplete="off"
                  dense
                />
              </v-app-bar>
              <v-sheet
                id="scrolling-techniques-7"
                class="overflow-y-auto"
                max-height="600"
              >
                <v-col cols="12">
                  <v-btn
                    v-for="(item, i) in allIcons"
                    :key="i"
                    @click="saveIcon(item)"
                    color="white"
                    class="mr-2 mb-2 "
                    fab
                    small
                    depressed
                  >
                    <v-icon color="grey darken-3"> mdi-{{ item }} </v-icon>
                  </v-btn>
                </v-col>
                
              </v-sheet>
            </v-card>
          </v-dialog> -->
          <span style="color:#5D6167;">URL</span>
          <v-text-field
                v-model="link.url"
                required
                :rules="nameRules"
                solo
          ></v-text-field>
          <v-switch
          label="Show link on sidebar"
            v-model="link.sidebar"
            inset
          ></v-switch>
          <v-switch
          label="Show to all accounts"
            v-model="link.all_account"
            inset
          ></v-switch>
          <v-select
          v-if="link.all_account == 0"
            v-model="link.list_account"
            :items="companies"
            chips
            item-text="name"
            item-value="id"
            label="Chips"
            multiple
            solo
          ></v-select>
          <v-switch
          label="Show to Dashboard"
            v-model="link.dashboard"
            inset
          ></v-switch>
          <v-switch
          label="Show to Client Profile"
            v-model="link.client"
            inset
          ></v-switch>
          <v-radio-group
            v-model="link.is_iframe"
            row
          >
            <v-radio
              label="Open in iFrame"
              :value="1"
            ></v-radio>
            <v-radio
              label="Open in a New Tab"
              :value="0"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!editFlag"
            :disabled="loadingCustomLink"
            @click="addLink()"
          >
            Submit
          </v-btn>
          <v-btn
            color="primary"
            text
            v-if="editFlag"
            :disabled="loadingCustomLink"
            @click="editLink()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingCustomLink"
            @click="dialogCustomLink = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteBasic" max-width="500px">
                                <v-card>
                                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeleteBasic">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmBasic(selected_item)">OK</v-btn>
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
    <v-dialog
        persistent
      v-model="dialogCoupon"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!review.id">
          Add Review
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Review
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
        <v-col cols="12" v-if="!review.id">
        <p class="mb-0 font-weight-bold text-body">Client</p>
                                                    <v-select
                                                      v-model="review.client"
                                                      :items="clients"
                                                      chips
                                                      item-text="name"
                                                      item-value="user_id"
                                                      label="Select users"
                                                      multiple
                                                      solo
                                                    >
                                                   <template v-slot:prepend-item>
                                                            <v-list-item>
                                                              <v-list-item-content>
                                                                <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                                                              </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider class="mt-2"></v-divider>
                                                            <v-list-item
                                                                ripple
                                                                @mousedown.prevent
                                                                @click="toggleAllAdd"
                                                              >
                                                                <v-list-item-action>
                                                                  <v-icon class="material-icons-round" style="font-size: 18px;margin-left: 3px;">
                                                                    {{ review.client.length == clients.length ? 'check_box': 'check_box_outline_blank' }}
                                                                  </v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                  <v-list-item-title>
                                                                    Select All
                                                                  </v-list-item-title>
                                                                </v-list-item-content>
                                                              </v-list-item>
                                                          </template>
                                                  </v-select>
        </v-col>
          <v-col cols="12" style="margin-top: -25px;">
                <p class="mb-0 font-weight-bold text-body">Name</p>
                <v-text-field
                  v-model="review.name"
                  class="mt-2"
                  dense
                  outlined
                  placeholder="Full name?"
                ></v-text-field>
                </v-col>
                <v-col cols="12" style="margin-top: -25px;">
                <p class="mb-0 font-weight-bold text-body">Overall rating</p>
                  <v-rating
                    v-model="review.rating"
                    color="yellow darken-3"
                    size="20"
                  ></v-rating>
                </v-col>
                <v-col cols="12" >
                <p class="mb-0 font-weight-bold text-body">Add a headline</p>
                <v-text-field
                  v-model="review.heading"
                  class="mt-2"
                  dense
                  outlined
                  placeholder="What's most important to know?"
                ></v-text-field>
                </v-col>
                
                <v-col cols="12" style="margin-top: -25px;">
                <p class="mb-0 font-weight-bold text-body">Add a photo or video</p>
                <button v-if="!review.file.name && !review.old_file" @click="handleUploaded"  type="button" data-hook="ryp-media-upload-button" class="ryp__media-upload-call-to-action--square mt-2"><div aria-label="Add a photo or video" style="background-image: url(&quot;data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUwLjIgKDU1MDQ3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJzaHJpbmtJbWFnZUNUQS04MCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImV4cGwtY29weS0yMjkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00Ny4wMDAwMDAsIC0zMjMuMDAwMDAwKSIgZmlsbD0iI0FBQjdCOCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9ImFzaW5NZXRhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTE5LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9ImFkZE1lZGlhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTAwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTcuMDAwMDAwLCAxNy4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA1Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJTaGFwZSIgcG9pbnRzPSI0NC4zIDQxLjcgNDQuMyAzMCA0MS43IDMwIDQxLjcgNDEuNyAzMCA0MS43IDMwIDQ0LjMgNDEuNyA0NC4zIDQxLjcgNTYgNDQuMyA1NiA0NC4zIDQ0LjMgNTYgNDQuMyA1NiA0MS43Ij48L3BvbHlnb24+CiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==&quot;);" class="a-section a-spacing-none ryp-media-upload-icon">
                <input @change="onFileChanged" accept="image/*,video/*" type="file" id="ryp__media-upload-banner-input" class="ryp__util__hidden" data-hook="ryp-media-upload-input-file" ref="file_id"></div></button>
                 <v-chip
                      v-if="review.file.name"
                      class="ma-2"
                      close
                      color="primary"
                      dark
                      @click:close="review.file = []"
                    >
                      {{review.file.name}}
                    </v-chip>
                    <v-chip
                      v-if="review.old_file"
                      class="ma-2"
                      close
                      color="primary"
                      dark
                      @click:close="review.file = [],review.old_file = ''"
                    >
                      {{review.old_file}}
                    </v-chip>
                    
                </v-col>
                <v-col cols="12" >
                <p class="mb-0 font-weight-bold text-body">Add your review</p>
                <v-textarea
                  v-model="review.description"
                  class="mt-2"
                  dense
                  outlined
                  placeholder="Write your review here"
                ></v-textarea>
                </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!review.id"
            :disabled="loadingAssign"
            @click="submitReview()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            v-else
            :disabled="loadingAssign"
            @click="submitReviewEdit()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogCoupon = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
    </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
import Sortable from 'sortablejs';
import moment from 'moment';
export default {
components: {
      VueTelInput,HtmlEditor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        id: '',
        name:'',
        editedItemBasic: {
          item: '',
        },
        payment_gateway_options: [],
        phone:'',
        password : '',
        users : [],
        nameRules: [
        v => !!v || 'Field is required'
      ],
        valid: true,
        page:1,
        promo: {
          name : '',
          type : 'Percentage',
          value : '',
          count : 1,
        },
        itemsPerPage:10,
        confpassword : '',
        dialogCoupon : false,
        role : '',
        searchTerm : '',
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Category', value: 'category',
            sortable: true },
          { text: 'Staus', value: 'status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        headers_fa : [
           { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
         headers_l : [
           { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        datatable_options_letter: {},
        datatable_options_fa: {},
        datatable_options_l: {},
        selectedReviewClients : [],
        dialogAddFA : false,
        dialogAddL : false,
        headers_coupons : 
        [  { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Rating', value: 'rating',
            sortable: true },
          { text: 'Heading', value: 'heading',
            sortable: true },
             { text: 'Description', value: 'description',
            sortable: true },
            { text: 'Assigned', value: 'edit',
            sortable: false,width: '50' },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },],
        datatable_options_coupons : {},
        coupons : [],
        page_coupons : 1,
        page_fa : 1,
        page_l : 1,
        itemsPerPage_coupons :10,
        selectedCompany: [],
        dialogAssign3: false,
        page_letter : 1,
        itemsPerPage_letter: 10,
        dialogAssign: false,
        base_url_img : process.env.VUE_APP_WASBI_URL,
        pageCount: 1,
        fa : {
          name : '',
          id : ''
        },
        location : {
          name : '',
          id : ''
        },
        pageCountLetter : 1,
        pageCountCoupons : 1,
        pageCountFA : 1,
        pageCountL : 1,
        pageLink:1,
        clients : [],
        pageCountLink: 1,
        dialogAssign2 : false,
        totalCount: 0,
        totalCountLink: 0,
        companyname : '',
        success_url : '',
        success_url_existing : false,
        previewbg:'',
        dialogDeleteBasic :  false,
        focus_area : [],
        headers_basic: [
          { text: 'Item', value: 'item' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        dialog_basic: false,
        locations : [],
        sector : '',
        no_of_employee : '',
        showPassword : false,
        loadingAssign: false,
        limit: 10,
        limit_letter: 10,
        totalCountLetter : 0,
 
        totalCountFA : 0,
        totalCountL : 0,
        clientCopy : [],
        letters : [],
        isDarkUpload : false,
        dialogCustomLink: false,
        loadingCustomLink: false,
        phoneFlag:0,
        emailFlag:0,
        link: {
          title:'',
          icon: 'mdi-web',
          url: '',
          is_iframe: 1,
          sidebar:1,
          all_account: 1,
          list_account:[],
          dashboard : 0,
          client: 0
        },
        product: {
          price: 0,
          type :'Subscription',
          name : '',
          list_account : [],
          all_account : 0,
          image : [],
          trial_flag : 0,
          success_url : 'https://10kpages.com/',
          trial : 3,
          items :[
            {
              price: 0,
              type :'Subscription',
              period : 'Monthly',
              trial_flag : 0,
              trial : 3,
              interval_length : 1,
              interval: 'month'
            }
          ]
        },
        country :'',
        datatable_options:{},
        otp_email:'',
        otp_phone:'',
        links: [],
        otp_phone_input:"",
        otp_email_input:"",
        review: {
          name: '',
          rating: 0,
          heading: '',
          description: '',
          file: [],
          client: [],
        },
        user_phone:'',
        selectedItems: [],
        editFlag: false,
        companies: [],
        expanded: [],
        user_email:'',
        headers_jobs: [
        { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Rating', value: 'rating',
            sortable: true },
          { text: 'Heading', value: 'heading',
            sortable: true },
             { text: 'Description', value: 'description',
            sortable: true },
            { text: 'Assigned', value: 'edit',
            sortable: false,width: '50' },
          { text: 'Date Submitted', value: 'created_at',
            sortable: true,width: '200' },
          // { text: 'Updated at', value: 'updated_at',
          //   sortable: true,width: '200' },
          { text: 'Edit', value: 'edit_review',
            sortable: false,width: '50' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
            { text: '', value: 'data-table-expand' },
        ],
        headers_links: [
          { text: 'Title', value: 'title',
            sortable: false },
          { text: 'URL', value: 'url',
            sortable: false },
          { text: 'Open Mode', value: 'is_iframe',
            sortable: false },
          { text: 'Created at', value: 'created_at',
            sortable: false,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: false,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: false,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        status:0,
        settings :{
            payment_gateway: '',
            test_mode : 1,
            grant_count: 10,
            list_account : [],
            backgroung_logo: '',
            display_to : 'All',
            pricing :{
                user_credit : "",
            }

        },
        tab: null,
        showDialog: false,
      icon: '',
      searchIcon: '',
      selectedIcon: 'mdi-check-outline',
      allIcons: [
        "account",
        "account-alert",
        "account-box",
        "account-box-outline",
        "account-check",
        "account-circle",
        "account-key",
        "tooltip-account",
        "account-minus",
        "web",
        "account-multiple",
        "account-multiple-outline",
        "account-multiple-plus",
        "account-network",
        "account-outline",
        "account-plus",
        "account-remove",
        "account-search",
        "account-star",
        "account-switch",
        "airballoon",
        "airplane",
        "airplane-off",
        "alarm",
        "alarm-check",
        "alarm-multiple",
        "alarm-off",
        "alarm-plus",
        "album",
        "alert",
        "alert-box",
        "alert-circle",
        "alert-octagon",
        "alpha",
        "alphabetical",
        "amazon",
        "google-cloud",
        "ambulance",
        "android",
        "android-debug-bridge",
        "android-studio",
        "apple",
        "apple-finder",
        "apple-ios",
        "apple-safari",
        "apps",
        "archive",
        "arrange-bring-forward",
        "arrange-bring-to-front",
        "arrange-send-backward",
        "arrange-send-to-back",
        "arrow-all",
        "arrow-bottom-left",
        "arrow-bottom-right",
        "arrow-collapse",
        "arrow-down",
        "arrow-down-bold",
        "arrow-down-bold-circle",
        "arrow-down-bold-circle-outline",
        "arrow-down-bold-hexagon-outline",
        "arrow-expand",
        "arrow-left",
        "arrow-left-bold",
        "arrow-left-bold-circle",
        "arrow-left-bold-circle-outline",
        "arrow-left-bold-hexagon-outline",
        "arrow-right",
        "arrow-right-bold",
        "arrow-right-bold-circle",
        "arrow-right-bold-circle-outline",
        "arrow-right-bold-hexagon-outline",
        "arrow-top-left",
        "arrow-top-right",
        "arrow-up",
        "arrow-up-bold",
        "arrow-up-bold-circle",
        "arrow-up-bold-circle-outline",
        "arrow-up-bold-hexagon-outline",
        "at",
        "attachment",
        "auto-fix",
        "auto-upload",
        "baby",
        "backburger",
        "backup-restore",
        "bank",
        "barcode",
        "barley",
        "barrel",
        "basket",
        "basket-fill",
        "basket-unfill",
        "battery",
        "battery-10",
        "battery-20",
        "battery-30",
        "battery-40",
        "battery-50",
        "battery-60",
        "battery-70",
        "battery-80",
        "battery-90",
        "battery-alert",
        "battery-charging-100",
        "battery-charging-20",
        "battery-charging-30",
        "battery-charging-40",
        "battery-charging-60",
        "battery-charging-80",
        "battery-charging-90",
        "battery-minus",
        "battery-negative",
        "battery-outline",
        "battery-plus",
        "battery-positive",
        "battery-unknown",
        "beach",
        "beaker",
        "beaker-outline",
        "beer",
        "bell",
        "bell-off",
        "bell-outline",
        "bell-ring",
        "bell-ring-outline",
        "bell-sleep",
        "beta",
        "bike",
        "binoculars",
        "bio",
        "biohazard",
        "bitbucket",
        "black-mesa",
        "blinds",
        "block-helper",
        "blogger",
        "bluetooth",
        "bluetooth-audio",
        "bluetooth-connect",
        "bluetooth-settings",
        "bluetooth-transfer",
        "blur",
        "blur-linear",
        "blur-off",
        "blur-radial",
        "bone",
        "book",
        "book-multiple",
        "book-open",
        "book-variant",
        "bookmark",
        "bookmark-check",
        "bookmark-music",
        "bookmark-outline",
        "bookmark-plus",
        "bookmark-remove",
        "border-all",
        "border-bottom",
        "border-color",
        "border-horizontal",
        "border-inside",
        "border-left",
        "border-none",
        "border-outside",
        "border-right",
        "border-top",
        "border-vertical",
        "bowling",
        "box",
        "briefcase",
        "briefcase-check",
        "briefcase-download",
        "briefcase-upload",
        "brightness-1",
        "brightness-2",
        "brightness-3",
        "brightness-4",
        "brightness-5",
        "brightness-6",
        "brightness-7",
        "brightness-auto",
        "broom",
        "brush",
        "bug",
        "bulletin-board",
        "bullhorn",
        "bus",
        "cake",
        "cake-variant",
        "calculator",
        "calendar",
        "calendar-blank",
        "calendar-check",
        "calendar-clock",
        "calendar-multiple",
        "calendar-multiple-check",
        "calendar-plus",
        "calendar-remove",
        "calendar-text",
        "calendar-today",
        "camcorder",
        "camcorder-off",
        "camera",
        "camera-front",
        "camera-front-variant",
        "camera-iris",
        "camera-party-mode",
        "camera-rear",
        "camera-rear-variant",
        "camera-switch",
        "camera-timer",
        "candycane",
        "car",
        "car-wash",
        "carrot",
        "cart",
        "cart-outline",
        "cash",
        "cash-100",
        "cash-multiple",
        "cash-usd",
        "cast",
        "cast-connected",
        "castle",
        "cat",
        "cellphone",
        "cellphone-android",
        "cellphone-dock",
        "cellphone-iphone",
        "cellphone-link",
        "cellphone-link-off",
        "cellphone-settings",
        "chair-school",
        "chart-arc",
        "chart-areaspline",
        "chart-bar",
        "chart-histogram",
        "chart-line",
        "chart-pie",
        "check",
        "check-all",
        "checkbox-blank",
        "checkbox-blank-circle",
        "checkbox-blank-circle-outline",
        "checkbox-blank-outline",
        "checkbox-marked",
        "checkbox-marked-circle",
        "checkbox-marked-circle-outline",
        "checkbox-marked-outline",
        "checkbox-multiple-blank",
        "checkbox-multiple-blank-outline",
        "checkbox-multiple-marked",
        "checkbox-multiple-marked-outline",
        "checkerboard",
        "chevron-double-down",
        "chevron-double-left",
        "chevron-double-right",
        "chevron-double-up",
        "chevron-down",
        "chevron-left",
        "chevron-right",
        "chevron-up",
        "church",
        "city",
        "clipboard",
        "clipboard-account",
        "clipboard-alert",
        "clipboard-arrow-down",
        "clipboard-arrow-left",
        "clipboard-check",
        "clipboard-outline",
        "clipboard-text",
        "clippy",
        "clock",
        "clock-fast",
        "close",
        "close-box",
        "close-box-outline",
        "close-circle",
        "close-circle-outline",
        "close-network",
        "closed-caption",
        "cloud",
        "apple-icloud",
        "cloud-check",
        "cloud-circle",
        "cloud-download",
        "cloud-outline",
        "cloud-off-outline",
        "cloud-upload",
        "cloud-refresh",
        "cloud-lock",
        "cloud-lock-outline",
        "cloud-question",
        "cloud-tags",
        "cloud-print",
        "cloud-print-outline",
        "cloud-search",
        "cloud-search-outline",
        "code-array",
        "code-braces",
        "code-equal",
        "code-greater-than",
        "code-less-than",
        "code-less-than-or-equal",
        "code-not-equal",
        "code-not-equal-variant",
        "code-string",
        "code-tags",
        "codepen",
        "coffee",
        "coffee-to-go",
        "color-helper",
        "comment",
        "comment-account",
        "comment-account-outline",
        "comment-alert",
        "comment-alert-outline",
        "comment-check",
        "comment-check-outline",
        "comment-multiple-outline",
        "comment-outline",
        "comment-plus-outline",
        "comment-processing",
        "comment-processing-outline",
        "comment-remove-outline",
        "comment-text",
        "comment-text-outline",
        "compare",
        "compass",
        "compass-outline",
        "console",
        "content-copy",
        "content-cut",
        "content-duplicate",
        "content-paste",
        "content-save",
        "content-save-all",
        "contrast",
        "contrast-box",
        "contrast-circle",
        "cow",
        "credit-card",
        "credit-card-multiple",
        "crop",
        "crop-free",
        "crop-landscape",
        "crop-portrait",
        "crop-square",
        "crosshairs",
        "crosshairs-gps",
        "crown",
        "cube",
        "cube-outline",
        "cube-unfolded",
        "cup",
        "cup-water",
        "currency-btc",
        "currency-eur",
        "currency-gbp",
        "currency-inr",
        "currency-rub",
        "currency-try",
        "currency-usd",
        "cursor-default",
        "cursor-default-outline",
        "cursor-move",
        "cursor-pointer",
        "database",
        "database-minus",
        "database-outline",
        "database-plus",
        "debug-step-into",
        "debug-step-out",
        "debug-step-over",
        "decimal-decrease",
        "decimal-increase",
        "delete",
        "delete-variant",
        "deskphone",
        "desktop-mac",
        "desktop-tower",
        "details",
        "deviantart",
        "diamond",
        "dice-1",
        "dice-2",
        "dice-3",
        "dice-4",
        "dice-5",
        "dice-6",
        "directions",
        "disqus",
        "division",
        "division-box",
        "dns",
        "domain",
        "dots-horizontal",
        "dots-vertical",
        "download",
        "drag",
        "drag-horizontal",
        "drag-vertical",
        "drawing",
        "drawing-box",
        "drone",
        "dropbox",
        "drupal",
        "duck",
        "dumbbell",
        "earth",
        "earth-off",
        "eject",
        "elevation-decline",
        "elevation-rise",
        "elevator",
        "email",
        "email-open",
        "email-outline",
        "emoticon",
        "emoticon-cool",
        "emoticon-devil",
        "emoticon-happy",
        "emoticon-neutral",
        "emoticon-poop",
        "emoticon-sad",
        "emoticon-tongue",
        "engine",
        "engine-outline",
        "equal",
        "equal-box",
        "eraser",
        "escalator",
        "evernote",
        "exclamation",
        "exit-to-app",
        "export",
        "eye",
        "eye-off",
        "eyedropper",
        "eyedropper-variant",
        "facebook",
        "facebook-messenger",
        "factory",
        "fan",
        "fast-forward",
        "ferry",
        "file",
        "file-cloud",
        "file-delimited",
        "file-document",
        "file-excel-box",
        "file-find",
        "file-image",
        "file-multiple",
        "file-music",
        "file-outline",
        "file-pdf",
        "file-pdf-box",
        "file-powerpoint",
        "file-powerpoint-box",
        "file-presentation-box",
        "file-video",
        "file-word",
        "file-word-box",
        "film",
        "filmstrip",
        "filmstrip-off",
        "filter",
        "filter-outline",
        "filter-remove-outline",
        "filter-variant",
        "fire",
        "firefox",
        "fish",
        "flag",
        "flag-checkered",
        "flag-outline",
        "flag-triangle",
        "flag-variant",
        "flash",
        "flash-auto",
        "flash-off",
        "flashlight",
        "flashlight-off",
        "flip-to-back",
        "flip-to-front",
        "floppy",
        "flower",
        "folder",
        "folder-account",
        "folder-download",
        "folder-google-drive",
        "folder-image",
        "folder-lock",
        "folder-lock-open",
        "folder-move",
        "folder-multiple",
        "folder-multiple-image",
        "folder-multiple-outline",
        "folder-outline",
        "folder-plus",
        "folder-remove",
        "folder-upload",
        "food",
        "food-apple",
        "food-variant",
        "football",
        "football-helmet",
        "format-align-center",
        "format-align-justify",
        "format-align-left",
        "format-align-right",
        "format-bold",
        "format-clear",
        "format-color-fill",
        "format-float-center",
        "format-float-left",
        "format-float-none",
        "format-float-right",
        "format-header-1",
        "format-header-2",
        "format-header-3",
        "format-header-4",
        "format-header-5",
        "format-header-6",
        "format-header-decrease",
        "format-header-equal",
        "format-header-increase",
        "format-header-pound",
        "format-indent-decrease",
        "format-indent-increase",
        "format-italic",
        "format-line-spacing",
        "format-list-bulleted",

        "format-paint",
        "format-paragraph",

        "format-size",
        "format-strikethrough",
        "format-subscript",
        "format-superscript",
        "format-text",
        "format-textdirection-l-to-r",
        "format-textdirection-r-to-l",
        "format-underline",
        "format-wrap-inline",
        "format-wrap-square",
        "format-wrap-tight",
        "format-wrap-top-bottom",
        "forum",
        "forward",
        "fridge",
        "fullscreen",
        "fullscreen-exit",
        "function",
        "gamepad",
        "gamepad-variant",
        "gas-station",
        "gavel",
        "gender-female",
        "gender-male",
        "gender-male-female",
        "gender-transgender",
        "gift",
        "git",
        "github",
        "glass-flute",
        "glass-mug",
        "glass-stange",
        "glass-tulip",
        "glasses",
        "gmail",
        "google",
        "google-chrome",
        "google-circles",
        "google-circles-communities",
        "google-circles-extended",
        "google-circles-group",
        "google-controller",
        "google-controller-off",
        "google-drive",
        "google-earth",
        "google-glass",
        "google-maps",

        "google-play",
        "google-plus",
        "google-hangouts",
        "grid",
        "grid-off",
        "group",

        "guitar-pick",
        "guitar-pick-outline",
        "hand-pointing-right",
        "hanger",

        "harddisk",
        "headphones",
        "headphones-box",
        "headphones-settings"
      ],


      }
    },
    directives: {
    sortableDataTable: {
      bind (el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
    methods: {
      saveOrder (event) {
      const movedItem = this.users.splice(event.oldIndex, 1)[0];
      this.users.splice(event.newIndex, 0, movedItem);
       axios({
          method: "POST",
           
          url: "/api/save-product-order",
          data : this.users,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            console.log('product order saved');

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    changeCompany(){
          this.loading = true
              var r = confirm("Are you sure, You went to assign Client?");
              
            if (r == true) {
              if(this.selectedReviewClients.length == 0){
                      Vue.$toast.error('Select Client', {
                      position: 'top-right'
                      });
                  }else{
                    this.loadingAssign = true;
                    axios
                .post("/api/assign-review-client",{id : this.selectedItem.id, selected_client : this.selectedReviewClients},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Client assigned to the review successfully', {
                            position: 'top-right'
                            });
                            this.dialogAssign2 = false;
                            this.loadingAssign = false;
                            this.getProducts();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                  }
                
            }else{
                return false;
            }
        },
        changeSuccessURL(){
          this.loading = true
              var r = confirm("Are you sure, You went to change?");
              if(this.selectedItems.length == 0){
                Vue.$toast.error("Select atleast one item", {
                  position: 'top-right'
                  });
                  r = false
                  this.loading = false
              }
            if (r == true) {
              if(!this.success_url){
                      Vue.$toast.error('Select URL', {
                      position: 'top-right'
                      });
                  }else{
                    this.loadingAssign = true;
                    axios
                .post("/api/change-success-url",{ids : this.selectedItems, success_url : this.success_url, success_url_existing : this.success_url_existing ? 1 : 0},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Thank you page changed to the product successfully', {
                            position: 'top-right'
                            });
                            this.dialogAssign3 = false;
                            this.loadingAssign = false;
                            this.getProducts();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                  }
                
            }else{
                return false;
            }
        },
    saveOrderLink (event) {
      const movedItem = this.links.splice(event.oldIndex, 1)[0];
      this.links.splice(event.newIndex, 0, movedItem);
       axios({
          method: "POST",
           
          url: "/api/save-link-order",
          data : this.links,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            console.log('product order saved');

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    openAdd(){
      this.link = {
          title:'',
          icon: 'mdi-web',
          url: '',
          is_iframe: 1,
          sidebar:1,
          all_account: 1,
          list_account:[],
        };
      this.dialogCustomLink = true;
    },
    isVideo(url) {
      return /\.(mp4|webm|3gp|mov)$/i.test(url);
    },
    isImage(url) {
      return /\.(jpeg|jpg|png|gif|bmp)$/i.test(url);
    },
    openEdit(item){
      
      this.editFlag = true;
      this.link = item;
      this.link.list_account = Array.isArray(this.link.list_account) ? this.link.list_account : JSON.parse(this.link.list_account)
      console.log(this.link);
      this.selectedIcon = item.icon;
      this.dialogCustomLink = true;
    },
    getUserName(id){
      let name = '';
      id.forEach(element => {
        this.clients.forEach(user => {
          if(user.user_id == element.user_id){
          
            name = name == '' ? this.removeAfterParenthesis(user.name) : name +', '+ this.removeAfterParenthesis(user.name) ;
          }
        });
      });
      return name
    },
    removeAfterParenthesis(str) {
      return str.split('(')[0].trim();
    },
    getSettings() {
    let flag = 1;
    let url =process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
           
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.id = data.id;
              this.settings = data.settings;
              this.previewbg = url+data.settings.backgroung_logo
            }


         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     dateFormat(date){
       return moment(date).format('MM-DD-YYYY');
     },
     openEditReview(item){
      
      this.selectedItem = item
      this.selectedReviewClients = [];
      item.users.forEach(element => {
        this.clients.forEach(user => {
          if(user.user_id == element.user_id){
            this.selectedReviewClients.push(user.user_id);
          }
        });
      });
      this.dialogAssign2 = true

     },
     toggleAll(){
          if (this.selectedReviewClients.length > 0) {
            this.selectedReviewClients = []
          }
          else {
            this.clients.forEach(element => {
              this.selectedReviewClients.push(element.user_id)
            })
            console.log(this.selectedReviewClients);
            
            }
        },
        toggleAllAdd(){
          if (this.review.client.length > 0) {
            this.review.client = []
          }
          else {
            this.clients.forEach(element => {
              this.review.client.push(element.user_id)
            })
            
            }
        },
     productEditOpen(item){
      this.product = ''
      this.product = item
      this.product.all_account = this.product.all_account == 'all' ? true : false
      this.product.list_account = this.product.all_account != 'all' && this.product.list_account != '' ? JSON.parse(this.product.list_account)  : []
      this.product.image = this.product.image != '' ? this.product.image.name ? this.product.image : new File([this.product.image], this.product.image, {
                  type: "text/plain",
                }) : '';
      console.log(this.product.items)
    this.product.items =  typeof this.product.items === 'object' ? this.product.items != null ? this.product.items : [] :  JSON.parse(this.product.items)
      this.dialogAssign=true
      console.log(this.product);
     },
     previewImageBG: function(event) {
      var input = event.target;
      console.log(input);
      if (input.files) {
        this.settings.backgroung_logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewbg = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    gotoClient(id){
      window.open('/admin/client-profile-detail/'+id, '_blank');
    },
     getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-company", {headers: headers})
            .then(response => {
                this.companies = response.data.data;
                this.companies.push({name: 'None',id:'None'})
            })
        },
    deleteItem(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-user-review/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getProducts();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLocation(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-location/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getL();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemConfirmBasic (plan) {
        this.product.items.splice(this.editedIndexBasic, 1)
        this.closeDeleteBasic()
      },
        deleteItemFA(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-focus-area/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getFA();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLetter(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-letter/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLetters();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        searchFruits(e) {
          if (!this.searchTerm) {
            this.clients = this.clientCopy;
          }
          console.log(this.clientCopy);
          this.clients = this.clientCopy.filter((fruit) => {
            return fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
          });
        },
        add () {
        this.product.items.push({
          price: 0,
          type :'Subscription',
          period : 'Monthly',
          trial_flag : 0,
          trial : 3,
            })
      },
      
      remove (index) {
          this.product.items.splice(index, 1)
      },
        deleteItemLink(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-link/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLinks();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemBasic (flag,item) {
        this.editedIndexBasic = this.product.items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialogDeleteBasic = true
      },
        closeBasic () {
        this.dialog_basic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item : ''})
          this.editedIndexBasic = -1
        })
      },
        getAddressData: function (addressData, placeResultData, id) {
            this.location.name = addressData.name;
        },
        editItemBasic (flag,item) {
        this.editedIndexBasic = this.product.items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialog_basic = true
      },
        saveBasic (item) {
        if (this.editedIndexBasic > -1) {
          Object.assign(this.product.items[this.editedIndexBasic], this.editedItemBasic)
        } else {
          this.product.items.push(this.editedItemBasic)
        }
        this.closeBasic()
      },
        deleteItemCoupon(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-coupon/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getCoupons();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        getAllClients() {
          let headers = {
              Authorization: "Bearer " + localStorage.getItem('token')
          }
          axios
         .get("/api/get-all-client", {headers: headers})
         .then(response => {
              this.clients = response.data.data;
              this.clientCopy = [...this.clients];
          })
        },
    getPayments(){
            this.loading = true;
           
            axios({
          method: "GET",
           
          url: "/api/get-all-payment-gateway",
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
            this.payment_gateway_options = response.data.data
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
    addPromo(){
        this.loadingAssign = true;
        if(!this.product.name){
          Vue.$toast.error('Name is required', {
            position: 'top-right'
            });
        }else if(!this.product.success_url){
          Vue.$toast.error('Success URL is required', {
            position: 'top-right'
            });
        }else{
          let flag = true;
          this.product.items.forEach(element => {
            if(element.type =='Subscription' && element.period =='Custom' && element.interval == 'month' && element.interval_length > 12){
              flag = false
              Vue.$toast.error('Billing period must be between 1 day and 1 year', {
              position: 'top-right'
              });
              this.loadingAssign = false;
            }
          });
          if(flag){
        let form  = new FormData();
        form.append('name', this.product.name);
        form.append('price', this.product.price);
        form.append('image', this.product.image);
        form.append('type', this.product.type);
        form.append('trial', this.product.trial);
        form.append('success_url', this.product.success_url);
        form.append('trial_flag', this.product.trial_flag ? 1 : 0);
        form.append('items', JSON.stringify(this.product.items));
        form.append('list_account', JSON.stringify(this.product.list_account));
        form.append('all_account', this.product.all_account ? 'all' : '');

        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-product-payment-link",
          data: form
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Product added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('Unable to save', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
            }
          }
          
            
      },
      addFocusArea(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-focus-area",
          data: this.fa
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Focus Area added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddFA = false;
                      this.loadingAssign = false;
                      this.getFA();
                      this.fa.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addLocation(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-location",
          data: this.location
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Location added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddL = false;
                      this.loadingAssign = false;
                      this.getL();
                      this.location.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addCoupon(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-coupon-codes",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Coupon added successfully', {
                      position: 'top-right'
                      });
                      this.dialogCoupon = false;
                      this.loadingAssign = false;
                      this.getCoupons();
                      this.promo = {
                            name : '',
                            type : 'Percentage',
                            value : '',
                            count : 1
                          };
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editCoupon(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-coupon/"+this.promo.id,
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Coupon edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogCoupon = false;
                      this.loadingAssign = false;
                      this.getCoupons();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      submitReview() {
        let self = this;
        if(self.review.name == ''){
          Vue.$toast.error("Please enter your name", {
            position: 'top-right'
          });
          return false;
        }
        else if(self.review.heading == ''){
          Vue.$toast.error("Please enter title", {
            position: 'top-right'
          });
          return false;
        }
        else if(self.review.client.length == 0){
          Vue.$toast.error("Please select client", {
            position: 'top-right'
          });
          return false;
        }
        else if(self.review.description == ''){
          Vue.$toast.error("Please enter description", {
            position: 'top-right'
          });
          return false;
        }else{
        self.isDarkUpload = true;
        const data = new FormData();
            if(self.review.file){
              data.append('file', self.review.file);
            }
              data.append('heading', self.review.heading);
              data.append('name', self.review.name);
              data.append('description', self.review.description);
              data.append('rating', self.review.rating);
             
              data.append('client', JSON.stringify(self.review.client));


            axios({
            method: "POST",
            url: "/api/add-review-admin",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: data
            })
            .then(response => {
            //this.$router.push('/reviews');
            this.dialogCoupon = false;
            this.getProducts();
            //self.getUserProfile();
            })
            .catch(err => {
                Vue.$toast.error("Unable to submit", {
                position: 'top-right'
                });
                this.isDarkUpload = false;
            });
        }
      },
      submitReviewEdit() {
        let self = this;
        if(self.review.name == ''){
          Vue.$toast.error("Please enter your name", {
            position: 'top-right'
          });
          return false;
        }
        else if(self.review.heading == ''){
          Vue.$toast.error("Please enter title", {
            position: 'top-right'
          });
          return false;
        }
        
        else if(self.review.description == ''){
          Vue.$toast.error("Please enter description", {
            position: 'top-right'
          });
          return false;
        }else{
        self.isDarkUpload = true;
        const data = new FormData();
            if(self.review.file){
              data.append('file', self.review.file);
            }
             data.append('old_file', self.review.old_file);
              data.append('heading', self.review.heading);
              data.append('name', self.review.name);
              data.append('description', self.review.description);
              data.append('rating', self.review.rating);


            axios({
            method: "POST",
            url: "/api/edit-review-admin/"+self.review.id,
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: data
            })
            .then(response => {
            //this.$router.push('/reviews');
            this.dialogCoupon = false;
            this.getProducts();
            //self.getUserProfile();
            })
            .catch(err => {
                Vue.$toast.error("Unable to submit", {
                position: 'top-right'
                });
                this.isDarkUpload = false;
            });
        }
      },
      handleUploaded(){        
        this.$refs['file_id'].click()
      },
      onFileChanged(e) {
        let self = this;
        let selectedFile = e.target.files[0]
        self.review.file = selectedFile
      },
      editPromo(){
        this.loadingAssign = true;
        let form  = new FormData();
        form.append('name', this.product.name);
        form.append('price', this.product.price);
        if(this.product.image.length !=0 && this.product.image.type != 'text/plain')
          form.append('image', this.product.image);
        form.append('old_image', this.product.image.length!= 0 ? this.product.image.name : '');
        form.append('type', this.product.type);
        form.append('trial', this.product.trial);
        form.append('success_url', this.product.success_url);
        form.append('trial_flag', this.product.trial_flag ? 1 : 0);
        form.append('items', JSON.stringify(this.product.items));
        form.append('list_account', JSON.stringify(this.product.list_account));
        form.append('all_account', this.product.all_account ? 'all' : '');

        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-product-payment-link/"+this.product.id,
          data: form
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Product edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editFocusArea(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-focus-area/"+this.fa.id,
          data: this.fa
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Focus Area edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddFA = false;
                      this.loadingAssign = false;
                      this.getFA();
                      this.fa.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      uploadLogoBG(){
      const data = new FormData();
      let url =process.env.VUE_APP_WASBI_URL
        data.append('file_logo', this.settings.backgroung_logo);
        axios({
          method: "POST",
          url: "/api/upload-logo-background-admin",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              console.log();
              this.settings.backgroung_logo = response.data.data
              this.saveSettings()
              localStorage.setItem('admin_bg',url+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
      editLocation(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-location/"+this.location.id,
          data: this.location
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Location edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddL = false;
                      this.loadingAssign = false;
                      this.getL();
                      this.location.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addLink(){
        if(this.$refs.form.validate()){
        this.loadingCustomLink = true;
        let req = this.link;
        req.icon = this.selectedIcon;
        req.list_account = JSON.stringify(req.list_account)
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-cusom-link",
          data: req
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Custom Link added successfully', {
                      position: 'top-right'
                      });
                      this.dialogCustomLink = false;
                      this.loadingCustomLink = false;
                      this.getLinks();
                      this.link.title = '';
                      this.link.url= '';
                      this.link.list_account= [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
              this.loadingCustomLink = false;
              })
        }
          
            
      },
      editLink(){
        if(this.$refs.form.validate()){
        this.loadingCustomLink = true;
        let req = this.link;
        req.icon = this.selectedIcon;
        req.list_account = JSON.stringify(req.list_account)
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-custom-link",
          data: req
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Custom Link edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogCustomLink = false;
                      this.loadingCustomLink = false;
                      this.getLinks();
                      this.link.title = '';
                      this.link.url= '';
                      this.link.list_account= [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
              this.loadingCustomLink = false;
              })
        }
          
            
      },
    getProducts(){
      this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              limit : 10,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-admin-reviews",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getFA(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_fa.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-focus-area",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.focus_area = data.data;
                this.pageCountFA = data.total_page
                this.totalCountFA = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getL(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_l.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-location",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.locations = data.data;
                this.pageCountL = data.total_page
                this.totalCountL = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getCoupons(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_coupons.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_coupons.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_coupons.sortBy,
              sortDesc : this.datatable_options_coupons.sortDesc && this.datatable_options_coupons.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-coupon-codes",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.coupons = data.data;
                this.pageCountCoupons = data.total_page
                this.totalCountCoupons = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLinks(){
      this.loading = true;
            this.offset = (this.pageLink - 1) * 10
            this.selected_project = name;
            let req = {
              limit : -1,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-custom-links",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.links = data.data;
                this.pageCountLink = data.total_page
                this.totalCountLink = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-letter",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.pageCountLetter = data.total_page
                this.totalCountLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    closeDeleteBasic () {
        this.dialogDeleteBasic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item:''})
          this.editedIndexBasic = -1
        })
      },
    saveSettings: function(e) {

        axios({
          method: "POST",
           
          url: "/api/update-company-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },
    saveIcon: function(icon) {
      this.icon = icon;
      this.selectedIcon = "mdi-" + icon;
      this.showDialog = false;
    }

 },
 
watch: {
  dialogDeleteBasic (val) {
        val || this.closeDeleteBasic()
      },
    searchIcon: function(oldSearchIcon, newSearchIcon) {
    let filteredIcons = [];
    if (this.searchIcon.length === 0) {
      filteredIcons = this.allIcons;
    } else {
      filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
    }
    this.allIcons = filteredIcons;
  },
  datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getProducts()
                },
                deep: true,
            },
  datatable_options_coupons : {
    handler () {
                this.getCoupons()
                },
                deep: true,
  },
  datatable_options_fa : {
    handler () {
                this.getFA()
                },
                deep: true,
  },
  datatable_options_l : {
    handler () {
                this.getL()
                },
                deep: true,
  }
},
created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      this.getAllClients()
    }
      
    },

}
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px; /* Adjust based on your column width */
  display: block;
}
</style>