<template>
    <div>
      <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="100%"
    elevation="4"
    v-for="user in users" v-bind:key="user.id" v-if="user.id"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

   <v-card-title>
      <v-avatar :color="getRandomColor()" size="35">
      <span class="white--text text-h6">{{getInitials(user.reviews.name)}}</span>
    </v-avatar>
      <span class="text-h6 font-weight-medium ml-2">{{user.reviews.name}}</span>
       <span class="text-h6 font-weight-light ml-2 float-right">- {{dateFormat(user.reviews.created_date)}}</span>
    </v-card-title>



    <v-card-text>
      <v-row
        align="center"
        class="mx-0 mt-2"
      >
        <v-rating
          :value="user.reviews.rating"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

       
      </v-row>
      
      <div class="my-4 text-subtitle-1 font-weight-bold">
        {{user.reviews.heading}}
      </div>

      <div>{{user.reviews.description}}</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>


    <v-card-text v-if="user.reviews.file">
            <img
                    v-if="isImage(user.reviews.file)"
                    class="w-51 shadow-lg mx-auto "
                    :src="base_url_img+'review/'+user.reviews.file"
                    lazy-src="https://picsum.photos/id/11/100/60"

                  >
                  </img>
                   <video
                   class="w-51 shadow-lg mx-auto "
                  v-else-if="isVideo(user.reviews.file)"
                  :src="base_url_img+'review/'+user.reviews.file"
                  controls
                  style="width: 100%; max-height: 200px;"
                ></video>
    </v-card-text>
  </v-card>

  </div>
</template>

<script>
import Query from "@/components/Forms/Query.vue";
import moment from 'moment';
export default {
props: ["users"],
components: {Query},
  data: () => ({
    query: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
       
    },
    colors: ['red', 'blue', 'green', 'purple', 'orange', 'pink', 'teal'], // List of colors
    overlay_inventory : false,
    selected_item: '',
    base_url_img : process.env.VUE_APP_WASBI_URL,

  }),
  methods: {
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
    getInitials(fullName) {
      const nameParts = fullName.split(" ");
  
      // Extract the first letter of each word and capitalize it
      const initials = nameParts.map(name => name.charAt(0).toUpperCase());
      
      // Join the initials together
      return initials.join("");
    },
    isVideo(url) {
      return /\.(mp4|webm|3gp|mov)$/i.test(url);
    },
    isImage(url) {
      return /\.(jpeg|jpg|png|gif|bmp)$/i.test(url);
    },
    dateFormat(date) {
      return moment(date).format('MMMM D, YYYY');
    },
    getParentDomain() {
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.');
        
        // Assuming the domain is of the form subdomain.example.com
        if (domainParts.length >= 2) {
            const parentDomain = domainParts.slice(-2).join('.');
            return parentDomain;
        } else {
            return hostname; // For cases like localhost or single part domains
        }
    },
    checkPermissionPurchaseProgram(flag) {
        let status = 0;
        if(this.company_settings.display_purchase_program == 'None'){
          return false
        }
        else if(this.company_settings.display_purchase_program == 'All'){
          return true
        }else{
          if(this.company_settings.display_purchase_program_custom.length != 0){
          this.company_settings.display_purchase_program_custom.forEach(val => {
            if(val == this.company.user_id){
              status = 1;
            }
            
          });
        }
          return status
        }
     },
    viewDetail(id){
      if(localStorage.getItem('view-inventory-flag') && localStorage.getItem('view-inventory-flag') == 1){
        this.$router.push('/view-inventory-details/'+id)
      }else{
        this.overlay_inventory = true
        this.selected_item = id
      }
    },
    gotoLinks(link){
      if(!link)
      link = '#'
      const newTab = window.open('', '_blank');
      newTab.location.href = link;
    },
    currencyFormat(value){
      const roundedValue = Math.round(value).toFixed(2);
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0, // Ensures no decimals are shown
          maximumFractionDigits: 0  // Ensures no decimals are shown
        }).format(roundedValue);
      },
      numberFormat(value){
        return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
          value,
  )
      },
    gotoCard(vcard){
      let id = vcard.domain
      let route = '';
      let url = 'http://'+id+'.'+this.getParentDomain();
        window.open(url, '_blank');

    },
  }
}
</script>

<style>
.v-window__next .v-icon{
  font-size: 25px!important;
}
.v-window__prev .v-icon{
  font-size: 25px!important;
}
.inventory-overlay .v-overlay__content{
  height:100%;width:100%;
}
.w-51{
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>